const axios = require('axios');
const config = require('../../config.json');

export const axiosRequest = async (method, urlPath, data = {}) => {
  try {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const token = user.token || '';

    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    if (urlPath == 'login') {
      headers = {
        'Content-Type': 'application/json'
      };
    }

    return await axios.request({
      method,
      url: `${config.apiEndpoint}/${urlPath}`,
      data,
      headers
    });
  } catch (error) {
    console.error('Request failed:', error);
    return {};
  }
};
