// store.js
import { createStore } from 'vuex';

export const store = createStore({
  state: {
    isLoggedIn: !!localStorage.getItem('user'),  // Initialize based on localStorage
  },
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
  },
  actions: {
    login({ commit }, userData) {
      localStorage.setItem('user', JSON.stringify(userData));
      commit('setLoginState', true);
    },
    logout({ commit }) {
      localStorage.removeItem('user');
      commit('setLoginState', false);
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
  },
});
