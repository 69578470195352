
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/LoginPage.vue';
import PacketsPage from './components/PacketsPage.vue';
import SettingsPage from './components/SettingsPage.vue';

const routes = [
  { path: '/', name: 'Login', component: LoginPage },
  { path: '/packets', name: 'Packets', component: PacketsPage },
  { path: '/settings', name: 'Settings', component: SettingsPage },
  { path: '/logout', name: 'Logout', redirect: () => {
    localStorage.removeItem('user');
    return '/';
  }},
  { path: '/:pathMatch(.*)*', redirect: '/packets' } // Redirect unknown paths to dashboard
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
