<template>
  <div class="w-100 center mt-5 p-3">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Live Packet Data</h5>

        <!-- Device Dropdown -->
        <div class="form-group mb-3">
          <label for="deviceSelect">Select Device:</label>
          <select
            v-model="selectedDevice"
            class="form-control"
            id="deviceSelect"
            @change="{
              vuelidate.selectedDevice.$touch(); 
              packetData = [];
              endMessage = null;
              error = null;
              }"
            :class="{
              'is-invalid': vuelidate.selectedDevice.$error,
              'is-valid': !vuelidate.selectedDevice.$error && vuelidate.selectedDevice.$dirty,
            }"
          >
            <option disabled value="">Please select a device</option>
            <option v-for="device in devices" :key="device.name" :value="device.name">
              {{ device.description ? `${device.name} (${device.description})` : device.name }}
            </option>
          </select>
          <div v-if="vuelidate.selectedDevice.$error" class="invalid-feedback">
            Please select a device.
          </div>
        </div>

        <!-- Number of Packets Input -->
        <div class="form-group mb-3">
          <label for="numPackets">Number of Packets to Display (0 = unlimited):</label>
          <input
            v-model="numPackets"
            type="number"
            class="form-control"
            id="numPackets"
            min="0"
            max="1000"
            placeholder="0 = unlimited"
            @blur="vuelidate.numPackets.$touch()"
            :class="{
              'is-invalid': vuelidate.numPackets.$error,
              'is-valid': !vuelidate.numPackets.$error && vuelidate.numPackets.$dirty,
            }"
          />
          <div v-if="vuelidate.numPackets.$error" class="invalid-feedback">
            Please enter a valid number of packets (0-1000).
          </div>
        </div>

        <!-- Capture Duration Input -->
        <div class="form-group mb-3">
          <label for="captureDuration">Capture Duration (in seconds):</label>
          <input
            v-model="captureDuration"
            type="number"
            min="0"
            max="3600"
            class="form-control"
            id="captureDuration"
            placeholder="Enter seconds to capture"
            @blur="vuelidate.captureDuration.$touch()"
            :class="{
              'is-invalid': vuelidate.captureDuration.$error,
              'is-valid': !vuelidate.captureDuration.$error && vuelidate.captureDuration.$dirty,
            }"
          />
          <div v-if="vuelidate.captureDuration.$error" class="invalid-feedback">
            Please enter a valid duration (0-3600 seconds).
          </div>
        </div>

        <div class="terminal" id="packets-table">
          <!-- Table for displaying packet data -->
          <table class="table table-dark table-hover borderless">
            <thead>
              <tr>
                <th @click="sortTable('index')">Index</th>
                <th @click="sortTable('timestamp')">Time</th>
                <th @click="sortTable('source')">Source</th>
                <th @click="sortTable('destination')">Destination</th>
                <th @click="sortTable('protocol')">Protocol</th>
                <th @click="sortTable('length')">Length</th>
                <th @click="sortTable('info')">Info</th>
                <th @click="sortTable('layers')">Layers</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(packet, index) in sortedPackets" :key="index" @click="showPacketDetails(packet)">
                <td>{{ index + 1 }}</td>
                <td>{{ dateDifference(packet.timestamp) }}</td>
                <td>{{ packet.source }}</td>
                <td>{{ packet.destination }}</td>
                <td>{{ packet.protocol }}</td>
                <td>{{ packet.length }}</td>
                <td>{{ packet.info }}</td>
                <td>{{ packet.layers.length }}</td>
              </tr>
            </tbody>
          </table>

          <!-- Modal for packet details -->
          <div class="modal fade" :class="{ show: isModalVisible }" style="display: block;" v-if="isModalVisible">
            <div class="modal-dialog modal-lg">
              <div class="modal-content terminal">
                <div class="modal-header">
                  <h5 class="modal-title">Packet Details</h5>
                  <button type="button" class="btn-close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                  <vue-json-pretty :data="selectedPacket" :showIcon="true" theme="dark"></vue-json-pretty>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isModalVisible" class="modal-backdrop fade show"></div>

          <!-- Error and End Messages -->
          <div v-if="endMessage" class="text-warning">{{ `INFO: ${endMessage}` }}</div>
          <div v-if="error" class="text-danger">{{ `ERROR: ${error}` }}</div>
        </div>

        <!-- Pause/Resume Capture Button -->
        <button
          @click="toggleCaptureOnly"
          :disabled="vuelidate.captureDuration.$invalid || vuelidate.numPackets.$invalid || vuelidate.selectedDevice.$invalid || (captureDuration == 0 && numPackets == 0)"
          :class="socket ? 'btn btn-warning' : 'btn btn-primary'"
          class="m-2"
        >
          {{ !socket ? 'Start Capture' : 'Pause Capture' }}
        </button>

        <!-- Start Capture with download Button -->
        <button
          @click="downloadPCAP"
          class="btn btn-secondary m-3"
          :disabled="!packetData?.length"
        >
          Download PCAP
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, between } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import { axiosRequest } from '../utils/axios';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import config from '../../config.json';

export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      packetData: [],
      selectedPacket: null,
      isModalVisible: false,
      sortKey: 'index',
      sortAsc: true,
      socket: null,
      numPackets: 0,
      captureDuration: 10,
      selectedDevice: '',
      devices: [],
      token: '',
      endMessage: null,
      startMessage: null,
      error: null,
      startTime: null
    };
  },
  validations() {
    return {
      numPackets: { required, between: between(0, 1000) },
      captureDuration: { required, between: between(0, 3600) },
      selectedDevice: { required },
    };
  },
  setup() {
    const vuelidate = useVuelidate();
    return { vuelidate };
  },
  computed: {
    sortedPackets() {
      return [...this.packetData].sort((a, b) => {
        const compareA = a[this.sortKey] || 0;
        const compareB = b[this.sortKey] || 0;
        return this.sortAsc ? compareA - compareB : compareB - compareA;
      });
    },
  },
  mounted() {
    this.fetchDevices();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = user.token || '';
    this.filename = this.selectedDevice + '_' + this.formattedDate();
  },
  methods: {
    async fetchDevices() {
      try {
        const response = await axiosRequest('get', 'packet/devices');
        this.devices = response.data;
      } catch (error) {
        console.error('Error fetching devices:', error);
        this.error = 'Error fetching devices';
      }
    },
    openWebSocket() {
      if (this.numPackets <= 0 && this.captureDuration <= 0) {
        const toast = useToast();
        toast.error('Capture Duration or Number of Packets must be greater than 0');
        return;
      }
      this.startMessage = 'Establishing websocket connection';
      this.filename = this.selectedDevice + '_' + this.formattedDate();
      const socketUrl = `${config.websocketEndpoint}/packet/watch?device=${this.selectedDevice}&filename=${this.filename}&token=${this.token}`;
      this.socket = new WebSocket(socketUrl);

      this.packetData = [];
      this.startTime = Date.now();
      this.socket.onmessage = (event) => {
        this.startMessage = 'Websocket connection established';
        this.packetData.push(JSON.parse(event.data));
        this.scrollToBottom();
        if (this.packetData?.length === this.numPackets) {
          this.socket.close();
          this.socket = null;
        }
      };

      this.socket.onerror = () => {
        this.error = 'Error connecting to WebSocket';
        if (this.socket) {
          this.socket.close();
        }
        this.socket = null;
      };

      this.socket.onclose = () => {
        this.scrollToBottom();
        console.log('WebSocket connection closed');
        this.endMessage = 'Websocket connection closed';
      };
    },
    stopCapture() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
        this.endMessage = 'Capture stopped.';
      }
    },
    formattedDate() {
      return new Date().toLocaleString('en-US', { hour12: false }).replace(',', '').replace(/\//g, '-').replace(/:/g, '-').replace(' ', '-');
    },
    dateDifference(timestamp) {
      let newTimestamp = new Date(timestamp).getTime(); // Given timestamp in milliseconds
      let differenceInSeconds = (newTimestamp - this.startTime) / 1000;
      return differenceInSeconds.toFixed(6);
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const terminal = document.getElementById('packets-table');
        terminal.scrollTop = terminal.scrollHeight;
        window.scrollTo(0, document.body.scrollHeight);
      });
    },

    async downloadPCAP() {
      try {
        const url = config.apiEndpoint + `/packet/download/${this.filename}?token=${this.token}`;
        window.open(url, '_blank');
        const toast = useToast();
        toast.success('PCAP file download started');
      } catch (error) {
        console.error('Error downloading PCAP:', error);
        const toast = useToast();
        toast.error('Error downloading PCAP file.');
      }
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
    showPacketDetails(packet) {
      this.selectedPacket = packet;
      this.isModalVisible = true;  // Show modal
    },
    closeModal() {
      this.isModalVisible = false;
    },
    toggleCaptureOnly() {
      this.vuelidate.$touch();
      if (this.vuelidate.$invalid) {
        const toast = useToast();
        toast.error('Please fix the validation errors.');
        return;
      }
      if (this.socket) {
        this.socket.close();
        this.socket = null;
        this.endMessage = 'Capture paused.';
      } else {
        this.error = null;
        this.startMessage = 'Capture started';
        this.openWebSocket();
        if (this.captureDuration > 0) {
          setTimeout(() => {
            this.stopCapture();
          }, this.captureDuration * 1000);
        }
      }
    },
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  },
};
</script>

<style>
.terminal {
  background-color: #333;
  color: #fff;
  max-height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
  padding: 10px;
  font-family: monospace;
  border-radius: 5px;
  cursor: grab;
}

.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.borderless td,
.borderless th {
  border: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #333;
  color: #fff;
}

/* Change the modal close button to white */
.btn-close {
  --bs-btn-close-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  width: 32px;    
  height: 32px;   
  padding: 0.75rem;
  opacity: 1;
}

</style>
