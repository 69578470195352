<template>
  <div class="w-100 center mt-5 p-3">
    <div class="card mb-3 pb-3" v-if="loaded">
      <form @submit.prevent="saveSettings">
        <!-- SMTP Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">SMTP Settings</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="smtpHost" class="form-label">Host</label>
                  <input type="text" v-model="smtpSetting.host" id="smtpHost" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="smtpPort" class="form-label">Port</label>
                  <input type="number" v-model="smtpSetting.port" id="smtpPort" class="form-control" />
                </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label for="smtpUsername" class="form-label">Username</label>
                <input type="text" v-model="smtpSetting.username" id="smtpUsername" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="smtpPassword" class="form-label">Password</label>
                <input type="password" v-model="smtpSetting.password" id="smtpPassword" class="form-control" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <label for="smtpFrom" class="form-label">From Email</label>
                <input type="email" v-model="smtpSetting.from" id="smtpFrom" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <!-- Notification Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">Notification Settings</h5>
          </div>
          <div class="card-body">
            <label for="notificationEmails" class="form-label">Notification Emails</label>
            <multiselect v-model="notificationSetting.emails" :options="emailOptions" :multiple="true" :taggable="true"  @tag="addNewEmail" placeholder="Add Emails" />

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="device" class="form-label">Device</label>
                <select
                  v-model="notificationSetting.device"
                  class="form-control"
                  id="device"
                  @change="{
                    vuelidate.notificationSetting.device.$touch(); 
                    packetData = [];
                    endMessage = null;
                    error = null;
                    }"
                  :class="{
                    'is-invalid': vuelidate.notificationSetting.device.$error,
                    'is-valid': !vuelidate.notificationSetting.device.$error && vuelidate.notificationSetting.device.$dirty,
                  }"
                >
                  <option disabled value="">Please select a device</option>
                  <option v-for="device in deviceOptions" :key="device.name" :value="device.name">
                    {{ device.description ? `${device.name} (${device.description})` : device.name }}
                  </option>
                </select>
                <div v-if="vuelidate.notificationSetting.device.$error" class="invalid-feedback">
                  Please select a device.
                </div>
              </div>
              <div class="col-md-6">
                <label for="watchSeconds" class="form-label">Watch Seconds</label>
                <input type="number" v-model="notificationSetting.watchSeconds" id="watchSeconds" class="form-control" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label for="maxHourlyEmails" class="form-label">Max Hourly Emails</label>
                <input type="number" v-model="notificationSetting.maxHourlyEmails" id="maxHourlyEmails" class="form-control" />
              </div>
              
              <div class="col-md-6">
                <label for="maxEmailPackets" class="form-label">Max Email Content Packets</label>
                <input type="number" v-model="notificationSetting.maxEmailPackets" id="maxEmailPackets" class="form-control" />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="maxPcapPackets" class="form-label">Max Attachment Packets</label>
                <input type="number" v-model="notificationSetting.maxPcapPackets" id="maxPcapPackets" class="form-control" />
              </div>
            </div>

            <!-- IP-Specific Normal Behaviors (Accordion) -->
            <div class="row mt-3">
              <div class="col-md-9">
                <h5 class="card-title">IP Normal Behaviors</h5>
              </div>
              <div class="col-md-3">
                <!-- Add New IP Behavior Button -->
                <button type="button" class="btn btn-secondary" @click="addBehavior">Add IP Behavior</button>
              </div>
            </div>
            <div class="accordion mt-3" role="tablist">
              <div
                v-for="(behavior, index) in notificationSetting.normalBehaviors"
                :key="index"
                class="card mb-1"
              >
                <div>
                  <button
                    type="button"
                    class="btn btn-tertiary w-20 fw-bold"
                    @click="toggleCollapse(index)"
                    :aria-expanded="openAccordions[index] ? 'true' : 'false'"
                  >
                    <span>{{ openAccordions[index] ? '- ' : '+ ' }}</span> IP Normal Behavior {{ index + 1 }}
                  </button>
                </div>

                <div
                  :id="'collapse-' + index"
                  :class="['collapse', { show: openAccordions[index] }]"
                >
                  <div class="card-body">
                    <!-- IP Address -->
                    <div class="row">
                      <div class="col-md-6">
                        <label :for="'ipAddress-' + index" class="form-label">IP Address</label>
                        <input
                          type="text"
                          v-model="behavior.ip"
                          :id="'ipAddress-' + index"
                          class="form-control"
                        />
                      </div>

                      <!-- MAC Address -->
                      <div class="col-md-6">
                        <label :for="'macAddress-' + index" class="form-label">MAC Address</label>
                        <input
                          type="text"
                          v-model="behavior.mac"
                          :id="'macAddress-' + index"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <!-- PPS Operator and Value -->
                    <label :for="'pps-' + index" class="form-label">PPS</label>
                    <div class="d-flex">
                      <select v-model="behavior.pps.operator" class="form-select me-2">
                        <option :value="0">{{ "=" }}</option>
                        <option :value="1">{{ ">=" }}</option>
                        <option :value="2">{{ "<=" }}</option>
                      </select>
                      <input type="number" v-model="behavior.pps.value" :id="'pps-' + index" class="form-control" />
                    </div>

                    <!-- Packet Length Operator and Value -->
                    <label :for="'packetLength-' + index" class="form-label">Packet Length</label>
                    <div class="d-flex">
                      <select v-model="behavior.packetLength.operator" class="form-select me-2">
                        <option :value="0">{{ "=" }}</option>
                        <option :value="1">{{ ">=" }}</option>
                        <option :value="2">{{ "<=" }}</option>
                      </select>
                      <input type="number" v-model="behavior.packetLength.value" :id="'packetLength-' + index" class="form-control" />
                    </div>

                    <!-- Protocol -->
                    <label :for="'protocol-' + index" class="form-label">Protocol</label>
                    <multiselect v-model="behavior.protocol" :options="protocolOptions" :id="'protocol-' + index" placeholder="Select Protocol"
                      :taggable="true" 
                      @tag="(newProtocol) => addNewBehaviorProtocol(newProtocol, index)"  
                    />

                    <!-- Contents -->
                    <label :for="'contents-' + index" class="form-label">Contents</label>
                    <multiselect 
                      v-model="behavior.contents" :options="contentOptions" :multiple="true" :taggable="true" 
                      @tag="(newContent) => addNewBehaviorContent(newContent, index)" 
                      placeholder="Select or Add Contents" 
                    />

                    <!-- Remove Behavior Button -->
                    <button type="button" class="btn btn-danger mt-2" @click="removeBehavior(index)">Remove</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Packet Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">Packet Settings</h5>
          </div>
          <div class="card-body">
            <!-- Max PCAP Age -->
            <label for="maxPcapAge" class="form-label mt-3">Max PCAP Age (minutes)</label>
            <input type="number" v-model="packetSetting.maxPcapAge" id="maxPcapAge" class="form-control" />
          </div>
        </div>
      </form>
      <!-- Save Button -->
      <button type="submit" class="btn btn-primary m-2" @click="saveSettings">Save Settings</button>
    </div>
    <div v-else class="text-center">
      <p>Loading settings...</p>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import { required, integer, email } from '@vuelidate/validators';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { axiosRequest } from '../utils/axios';

const toast = useToast();

export default {
  components: { Multiselect },
  data() {
    return {
      loaded: false,
      smtpSetting: {
        host: '',
        port: '',
        username: '',
        password: '',
        from: ''
      },
      notificationSetting: {
        emails: [],
        normalBehaviors: [
          {
            ip: '',
            mac: '',
            pps: { operator: 0, value: 0 },
            packetLength: { operator: 0, value: 0 },
            protocol: '',
            contents: []
          }
        ],
        device: "any",
        watchSeconds: 30,
        maxHourlyEmails: 5,  
        maxEmailPackets: 500,
        maxPcapPackets: 10000
      },
      packetSetting: {
        maxPcapAge: 60
      },
      openAccordions: [], // Array to track the state of each accordion
      protocolOptions: ['TCP', 'UDP', 'ICMP'],
      contentOptions: ['ACK', 'SYN', 'RST', 'URG'],
      emailOptions: [],
      deviceOptions: []
    };
  },
  validations() {
    return {
      smtpSetting: {
        host: { required },
        port: { required, integer },
        username: { required },
        password: { required },
        from: { required, email }
      },
      notificationSetting: {
        normalBehaviors: {
          $each: {
            ip: {  },
            mac: {  },
            pps: { operator: { required }, value: { required, integer } },
            packetLength: { operator: { required }, value: { required, integer } },
            protocol: {  },
            contents: {  }
          }
        },
        watchSeconds: { required, integer },
        maxHourlyEmails: { required, integer },
        device: { required },
      },
      packetSetting: {
        maxPcapAge: { integer }
      }
    };
  },
  setup() {
    const vuelidate = useVuelidate();
    return { vuelidate };
  },
  mounted() {
    this.getSettings();
    this.getDevices();
  },
  methods: {
    async getSettings() {
      try {
        const response = await axiosRequest('get', 'settings');
        const data = response.data;
        this.smtpSetting = data.smtpSetting;
        this.notificationSetting = data.notificationSetting;
        this.packetSetting = data.packetSetting;
        this.emailOptions = data.notificationSetting.emails;
        this.openAccordions = this.notificationSetting.normalBehaviors.map(() => false); // Initialize all accordions to closed
        this.loaded = true;
      } catch (error) {
        console.error('Error fetching settings:', error);
        toast.error('Error fetching settings');
      }
    },
    async getDevices() {
      try {
        const response = await axiosRequest('get', 'packet/devices');
        this.deviceOptions = response.data;
      } catch (error) {
        console.error('Error fetching devices:', error);
        this.error = 'Error fetching devices';
      }
    },
    async saveSettings() {
      // Run validation
      this.vuelidate.$touch();
      if (this.vuelidate.$invalid) {
        toast.error('Please fix the validation errors');
        return;
      }

      try {
        const response = await axiosRequest('put', 'settings', {
          smtpSetting: this.smtpSetting,
          notificationSetting: this.notificationSetting,          
          packetSetting: this.packetSetting
        });
        if (response.status == 200) {
          toast.success('Settings saved successfully');
          this.getSettings();  // Refetch the settings after saving
        } else {
          toast.error('Failed to save settings');
        }
      } catch (error) {
        console.error('Error saving settings:', error);
      }
    },
    addBehavior() {
      this.notificationSetting.normalBehaviors.push({
        ip: '',
        mac: '',
        pps: { operator: 0, value: 0 },
        packetLength: { operator: 0, value: 0 },
        protocol: '',
        contents: []
      });
      this.openAccordions.push(true); // Automatically expand the new behavior accordion
      // Wait for the DOM to update, then scroll to the new accordion
      this.$nextTick(() => {
        const lastAccordion = document.getElementById(`collapse-${this.notificationSetting.normalBehaviors.length - 1}`);
        if (lastAccordion) {
          lastAccordion.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    removeBehavior(index) {
      this.notificationSetting.normalBehaviors.splice(index, 1);
      this.openAccordions.splice(index, 1); // Remove the corresponding accordion state
    },
    addNewBehaviorContent(newContent, index) {
      this.notificationSetting.normalBehaviors[index].contents.push(newContent);
    },
    addNewBehaviorProtocol(newProtocol, index) {
      this.notificationSetting.normalBehaviors[index].protocol = newProtocol;
    },
    addNewEmail(newEmail) {
      this.notificationSetting.emails.push(newEmail);
    },
    toggleCollapse(index) {
      // Toggle the state of the clicked accordion
      this.openAccordions = this.openAccordions.map((isOpen, i) => (i === index ? !isOpen : isOpen));
    }
  }
};
</script>

<style>
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.collapse {
  transition: height 0.35s ease;
}
</style>
