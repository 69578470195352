<template>
  <div class="container mt-5 pt-3">
    <h1 class="text-center mb-3">Login</h1>
    <div class="text-center mb-3"><img src="/logo.png" alt="Network Nurse" width="60" height="60" class="text-center"/></div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="username" class="form-label">Username</label>
                <input type="text" v-model="username" class="form-control" id="username" />
                <div v-if="vuelidate.username.required && vuelidate.username.$invalid" class="text-danger">Username is required</div>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" v-model="password" class="form-control" id="password" />
                <div v-if="vuelidate.password.required && vuelidate.password.$invalid" class="text-danger">Password is required</div>
              </div>
              <div class="d-grid gap-2">
                <button type="submit" class="btn btn-primary">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { axiosRequest } from '../utils/axios';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  validations() {
    return {
      username: { required },
      password: { required }
    };
  },
  setup() {
    const vuelidate = useVuelidate();
    return { vuelidate };
  },
  methods: {
    async login() {
      const toast = useToast();

      // Run validation
      this.vuelidate.$touch();
      if (this.vuelidate.$invalid) {
        toast.error('Please fix the validation errors');
        return;
      }

      try {
        const response = await axiosRequest('post', 'login',  { username: this.username,  password: this.password });
        if (response.status == 200) {
          // Use Vuex to handle login
          this.$store.dispatch('login', response.data);
          this.$router.push('/packets');
        } 
        else {
          toast.error('Login failed');
        }
      } catch (error) {
        console.error('Error logging in:', error);
        toast.error('Error logging in');
      }
    },
  },
};
</script>

<style>
.container {
  max-width: 500px;
}
</style>
